import * as React from "react";

import LocalizedLink from "../components/localizedLink";
import useTranslations from "../components/useTranslations";
import Seo from "../components/seo";
import { LocaleContext } from "../components/layout";

const NotFoundPage = () => {
  const { locale } = React.useContext(LocaleContext);
  const { homepage, notFoundPage, notFoundTitle, notFoundText } =
    useTranslations();

  return (
    <>
      <Seo
        lang={locale}
        title={notFoundTitle}
        description={notFoundText}
        bodyClass="page-notfound"
      />
      <main className="notfound container">
        <title>{notFoundPage}</title>
        <h1 className="notfound__title">{notFoundTitle}</h1>
        <p>
          {notFoundText}
          <br />
          <LocalizedLink to="/">{homepage}</LocalizedLink>.
        </p>
      </main>
    </>
  );
};

export default NotFoundPage;
